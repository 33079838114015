<template>
  <div>
    <h2 class="is-size-5 has-text-weight-bold has-text-dark mb-4">{{ $t('warehouse.warehouse') }}</h2>

    <header>
      <div class="is-flex-tablet is-justify-content-space-between is-align-items-center">
        <b-input icon="magnify" :placeholder="$t('search')" v-model="search" class="mb-2"/>

        <div class="is-hidden-tablet my-3"></div>

        <div v-if="canAddWarehouse" class="has-text-centered-mobile ml-auto">
          <b-button type="is-danger is-gradient" icon-left="plus" @click="addModal = true">{{ $t('warehouse.add_item') }}</b-button>
        </div>
      </div>

      <div class="btn-tabs my-3">
        <b-button :type="selectedCategory ? 'is-text' : 'is-danger is-light'" class="has-text-weight-bold mr-2"
                  @click="selectCategory(null)">{{ $t('all') }}
        </b-button>
        <b-button
            :type="selectedCategory && selectedCategory.category_id === category.category_id ? 'is-danger is-light' :'is-text'"
            class="has-text-weight-bold mr-2" v-for="(category, key) in categories" :key="key"
            @click="selectCategory(category)">{{ category.category_name }}
        </b-button>
      </div>
    </header>

    <!--   Product List  -->

    <section>

      <b-table :mobile-cards="false" :data="data">
        <template #empty>
          <p class="has-text-centered">{{ $t('no') }} Data</p>
        </template>
        <b-table-column field="product_name" :label="$t('warehouse.product_name')" v-slot="props">
          {{ props.row.product_name }}
        </b-table-column>
        <b-table-column field="category_id" :label="$t('category')" v-slot="props">
          <b-tag :type="getCategoryColor(props.row.category_name)">{{ $t('warehouse.' + props.row.category_name.toLowerCase()) }}</b-tag>
        </b-table-column>
        <b-table-column field="qty" :label="$t('warehouse.qty')" v-slot="props">
          <span class="has-text-weight-bold" :class="{
                    'has-text-success': props.row.qty > (props.row.min_qty ? props.row.min_qty - 1 : 0),
                    'has-text-danger': props.row.qty <= (props.row.min_qty ? props.row.min_qty - 1 : 0),
                  }">
            {{ $number(props.row.qty) }} {{ props.row.unit }}
          </span>
        </b-table-column>
        <b-table-column v-slot="props">
          <div class="is-flex is-justify-content-flex-end">
            <b-button type="is-success" class="mr-2" @click="restock(props.row)"
                      icon-left="plus"></b-button>
            <b-button type="is-danger" class="mr-2" @click="remove(props.row)"
                      icon-left="minus"></b-button>
            <b-button type="is-info is-light" tag="router-link" :to="`/farm/${farm_id}/warehouse/${props.row.warehouse_id}`"
                      icon-left="format-list-bulleted"></b-button>
          </div>
        </b-table-column>
      </b-table>

      <b-pagination
          v-show="totalItem"
          class="pagination-borderless my-5"
          :total="totalItem"
          v-model="page"
          @change="getData"
          order="is-centered"
          :per-page="10"
          aria-next-label="Next page"
          aria-previous-label="Previous page"
          aria-page-label="Page"
          aria-current-label="Current page">
        <b-pagination-button
            slot="previous"
            slot-scope="props"
            :page="props.page">
          Prev
        </b-pagination-button>

        <b-pagination-button
            slot="next"
            slot-scope="props"
            :page="props.page">
          Next
        </b-pagination-button>
      </b-pagination>

    </section>

    <!--  Modal Add Item -->

    <b-modal
        v-model="addModal"
        has-modal-card
        :destroy-on-hide="true"
        aria-role="dialog"
        aria-modal>
      <template #default="props">
        <add-item is-modal @close="props.close"></add-item>
      </template>
    </b-modal>

    <!--  Modal Use Item -->

    <b-modal
        v-model="useModal"
        has-modal-card
        :destroy-on-hide="true"
        aria-role="dialog"
        aria-modal>
      <template #default="props">
        <use-item :warehouse="warehouse" is-modal @close="props.close"></use-item>
      </template>
    </b-modal>

    <!--  Modal  Restock -->

    <b-modal
        v-model="restockModal"
        has-modal-card
        :destroy-on-hide="true"
        aria-role="dialog"
        aria-modal>
      <template #default="props">
        <restock-item :warehouse="warehouse" is-modal @close="props.close"></restock-item>
      </template>
    </b-modal>

    <!--  Modal  Remove -->

    <b-modal
        v-model="removeModal"
        has-modal-card
        :destroy-on-hide="true"
        aria-role="dialog"
        aria-modal>
      <template #default="props">
        <remove-item :warehouse="warehouse" is-modal @close="props.close"></remove-item>
      </template>
    </b-modal>

  </div>
</template>

<script>
import debounce from 'lodash/debounce'

export default {
  name: "Warehouse",
  components: {
    AddItem: () => import("@/components/Farm/Warehouse/AddItem"),
    UseItem: () => import("@/components/Farm/Warehouse/UseItem"),
    RemoveItem: () => import("@/components/Farm/Warehouse/RemoveItem"),
    RestockItem: () => import("@/components/Farm/Warehouse/RestockItem"),
  },
  data: () => {
    return {
      current: 1,
      restockModal: false,
      removeModal: false,
      addModal: false,
      useModal: false,
      synthesizeModal: false,

      data: [],
      selectedCategory: null,

      totalPage: 0,
      totalItem: 0,
      page: 1,
      warehouse: null,

      search: '',
    }
  },
  computed: {
    canAddWarehouse() {
      return this.$store.getters["farm/canAddWarehouse"]
    },
    farm_id() {
      return parseInt(this.$route.params.farm_id)
    },
    selectedFarm() {
      return this.$store.state.farm.selectedFarm
    },
    categories() {
      return this.$store.state.farm.warehouse.categories
    },
    refresh_data() {
      return this.$store.state.farm.warehouse.refresh_data
    },
    loading() {
      return this.$store.state.loading
    }
  },
  watch: {
    'selectedFarm.farm_id'() {
      this.getData()
    },
    refresh_data(value) {
      if (value) this.getData()
    },
    search: debounce(function () {
      this.page = 1
      this.getData()
    }, 500)
  },
  created() {
    this.getData()
  },
  methods: {
    selectCategory(category = null) {
      this.page = 1
      this.selectedCategory = category
      this.getData()
    },

    getCategoryColor(category_id) {
      switch (category_id) {
        case 'Feed':
          return 'is-info'
        case 'Chemical':
          return 'is-success'
        case 'Probiotics':
          return 'is-warning'
        default:
          return 'is-secondary is-light'
      }
    },

    async getData() {
      this.$loading()

      if (this.selectedFarm && this.selectedFarm.farm_id) {
        this.data = []
        this.totalPage = 0
        this.totalItem = 0
        let payload = {
          farm_id: this.selectedFarm.farm_id,
          page: this.page,
          product_name: this.search,
          limit: 10,
        }

        if (this.selectedCategory) payload.category_id = this.selectedCategory.category_id

        let res = {}

        if (this.search) res = await this.$store.dispatch('farm/searchWarehouse', payload)
        else res = await this.$store.dispatch('farm/getWarehouse', payload)

        if (!res.error && res.data && res.data.data && res.data.data.length) {
          this.data = res.data.data
          this.totalPage = res.data.paging.total_page
          this.totalItem = res.data.paging.total_page * 10
        }

        this.$store.dispatch('farm/refreshWarehouse', false)
      }

      this.$loading(false)
    },

    useItem(item) {
      this.warehouse = item
      this.useModal = true
    },
    restock(item) {
      this.warehouse = item
      this.restockModal = true
    },
    remove(item) {
      this.warehouse = item
      this.removeModal = true
    },
  }
}
</script>
